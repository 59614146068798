import React from 'react'

const LoadingComponents = () => {
  return (
    <svg
      className="w-3/4 flex flex-row h-[500px] mt-24 mb-10 shadow-md animate-pulse"
      // width="100%"
      // height="100%"
      viewBox="0 0 800 500"
    >
      {/* Placeholder Rectangle */}
      <rect x="0" y="0" width="400" height="500" fill="#f2f2f2" />

      {/* Avatar Placeholder Circle */}
      <circle cx="760" cy="40" r="20" fill="#e0e0e0" />

      {/* User Name Placeholder */}
      <rect x="500" y="25" width="200" height="20" rx="5" fill="#e0e0e0" />

      {/* Event Title Placeholder */}
      <rect x="410" y="80" width="380" height="40" rx="5" fill="#e0e0e0" />

      {/* Event Description Placeholder */}
      <rect x="410" y="140" width="380" height="30" rx="5" fill="#e0e0e0" />

      {/* College Name Placeholder */}
      <rect x="410" y="180" width="380" height="30" rx="5" fill="#e0e0e0" />

      {/* Start Date Placeholder */}
      <rect x="410" y="220" width="380" height="30" rx="5" fill="#e0e0e0" />

      {/* End Date Placeholder */}
      <rect x="410" y="260" width="380" height="30" rx="5" fill="#e0e0e0" />
    </svg>
  );
}

export default LoadingComponents
