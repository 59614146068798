import React, { useEffect, useRef, useState } from "react";
import { supabase } from "../utils/supaBaseClient";
import {
  CalendarIcon,
  HeartIcon,
  MapPinIcon,
  ShareIcon,
} from "@heroicons/react/24/solid";
import Comments from "../components/Comments";
import { Link } from "react-router-dom";
import LoadingComponents from "../components/LoadingComponents";
import {Maps} from "../components/Maps";
function Events({ user }) {
  // console.log(user);
  const [loading, setLoading] = useState(true);
  const [overyonder, setOveryonder] = useState({});
  const [upvote, setUpvote] = useState(0);
  const [categories, setCategories] = useState({});

  const [selectedTab, setSelectedTab] = useState("All Posts");
  const calendarContainer = useRef({});
  const calendarElement = useRef({});

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  let readableDate = (e) => {
    let date = new Date(e);
    return `${date.toDateString()} ${date.toLocaleTimeString()}`;
  };

  let replacer = (matched) => {
    let withProtocol = matched;

    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched;
    }

    const newStr = `<a
    className="text-blue-600 text-base dark:text-blue-500 hover:underline"
    href="${withProtocol}" target="_blank"
  >
    ${matched}
  </a>`;

    return newStr;
  };

  const deleteEvent = async (event_id) => {
    try {
      await supabase
        .from("user_event")
        .delete()
        .match({ user_id: user.id, event_id: event_id });
    } catch (error) {
      console.log(error.message);
    }
    console.log("Event deleted successfully!");
  };
  const attend = async (id, event_id) => {
    const { data, error } = await supabase
      .from("user_event")
      .insert({ user_id: user.id, event_id: event_id });
    if (error) {
      console.log(error.message);
    }
    console.log(data)
  };

  const toggleStyleEvent = async (id) => {
    calendarContainer.current[id].classList.toggle("bg-over-yonder-default");
    calendarElement.current[id].classList.toggle("text-white");
    calendarElement.current[id].classList.toggle("text-over-yonder-default");
  };

  const attendEvent = async (id, event_id) => {
    toggleStyleEvent(id);

    if (
      calendarContainer.current[id].classList.contains("bg-over-yonder-default")
    ) {
      await attend(id, event_id);
    } else {
      await deleteEvent(event_id);
    }
  };

  async function getCategories() {
    let { data, error } = await supabase
      .from("interest_categories")
      .select("*");
    if (error) {
      console.log(error.message);
    } else if (data) {
      setCategories(data);
      console.log(data);
    }
    console.log(categories);
  }
  useEffect(() => {
    getCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getEvents() {
      setLoading(true);

      let { data: event_post, error } = await supabase
        .from("event_post")
        .select(
          "* , user_profiles(id, full_name, first_name, last_name, avatar_url), schools(college_name), interest_categories(title), event_interest_tags(tag_id, event_id), user_event(user_id, event_id), event_comments(created_by, event_id,text)"
        )
        .order("created_at", { ascending: false });
      if (error) {
        console.log(error.message);
      } else if (event_post) {
        if (selectedTab === "All Posts") {
          setOveryonder(event_post);
        } else {
          const filteredEvents = event_post.filter(
            (event) => event.interest_categories.title === selectedTab
          );
          setOveryonder(filteredEvents);
        }
        setLoading(false);
      }
    }
    getEvents();
    // getCategories();
    // console.log(overyonder);
  }, [user, selectedTab]);

  return (
    <div className=" row-span-4 h-full relative">
      <ul className="flex flex-row flex-nowrap content-around justify-around sticky top-[65px] bg-white shadow-md overflow-x-scroll">
        {/* {categories.map((category,key) => (
          <li key={key} className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === category.title
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange(category.title)}
          >
            {category.title}
          </li>  
        ))
        } */}
        <li
          className={`p-2 text-center  hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "All Posts"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("All Posts")}
        >
          All Posts 📺
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Athletics"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Athletics")}
        >
          Athletics ⚽️
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Campus Events"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Campus Events")}
        >
          Campus Events
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Good Eats"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Good Eats")}
        >
          Good Eats 🥖
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Politics"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Politics")}
        >
          Politics 🗳
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Night Life"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Night Life")}
        >
          Night Life 🍻
        </li>
        <li
          className={`p-2 text-center hover:bg-over-yonder-default hover:text-white w-1/4 cursor-pointer ${
            selectedTab === "Greek Life"
              ? "bg-over-yonder-default text-white"
              : ""
          }`}
          onClick={() => handleTabChange("Greek Life")}
        >
          Greek Life 🏛
        </li>
      </ul>
      <div className="h flex flex-col content-around flex-nowrap justify-around items-center h-auto">
        {loading ? (
          <LoadingComponents  />
        ) : (
          <>
            {overyonder &&
              overyonder.map((event_post, key) => {
                // console.log(event_post.event_comments);
                let url = `http://maps.apple.com/?q=${encodeURIComponent(
                  event_post.title
                ).trim()}&ll=${event_post.latitude},${event_post.longitude}`;
                let urlGoogle = ` https://www.google.com/maps/search/?api=1&query=${event_post.latitude},${event_post.longitude}`;
                console.log(urlGoogle);
                console.log(url)
                return (
                  <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-7 mb-10 w-full md:w-1/2  lg:w-3/4">
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">
                          {event_post?.title}
                        </h2>
                        <span className="md:text-sm text-xs text-center text-gray-500">
                          {readableDate(event_post.start_date)}
                        </span>
                        <Link to={`/user/${event_post.user_profiles.id}`}>
                          <div className="w-[100%] flex flex-row-reverse flex-wrap items-center py-2 lg:py-0 px-2 ">
                            <img
                              className="h-[40px] w-[40px] rounded-[50%] avatar border cursor-pointer border-over-yonder"
                              src={event_post.user_profiles.avatar_url}
                              alt="avatar"
                              title={
                                event_post.user_profiles.full_name ||
                                `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`
                              }
                            />
                            <p className="mx-2">
                              {event_post.user_profiles.full_name ||
                                `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`}
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="flex md:flex-col-reverse lg:flex-row justify-between">
                        <div className="flex flex-col w-full justify-between">
                          <p className="text-gray-800 text-2xl md:pt-4">
                            {event_post?.description
                              .trim()
                              .replace(
                                /(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g,
                                replacer
                              )
                              .split("\\n")
                              .map((item, key) => (
                                <React.Fragment key={key}>
                                  <span
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  ></span>
                                  <br />
                                </React.Fragment>
                              ))}
                          </p>
                          <div className="flex items-center justify-between pt-4 lg:mr-4 border-t">
                            <div className="flex items-center w-full">
                              <button
                                className={`p-2 mx-1 w-fit h-fit border rounded-lg flex flex-wrap justify-center items-center ${
                                  event_post.user_event.filter(
                                    (event) =>
                                      event.user_id === user.id &&
                                      event.event_id === event_post.id
                                  ).length
                                    ? "bg-over-yonder-default text-white"
                                    : ""
                                }`}
                                ref={(el) =>
                                  (calendarContainer.current[key] = el)
                                }
                              >
                                <CalendarIcon
                                  onClick={() =>
                                    attendEvent(key, event_post.id)
                                  }
                                  ref={(el) =>
                                    (calendarElement.current[key] = el)
                                  }
                                  className={`h-6 w-6 ${
                                    event_post.user_event.filter(
                                      (event) =>
                                        event.user_id === user.id &&
                                        event.event_id === event_post.id
                                    ).length
                                      ? "text-white"
                                      : "text-over-yonder-default"
                                  } `}
                                />
                              </button>

                              <button
                                className={`p-2 w-fit mx-1 h-fit border rounded-lg flex flex-wrap justify-center items-center
                              `}
                              >
                                <ShareIcon
                                  className={`h-6 w-6  text-over-yonder-default
                               `}
                                />
                              </button>
                              <button
                                onClick={() => setUpvote(upvote + 1)}
                                className="p-2 w-fit mx-1 h-fit border rounded-lg flex flex-wrap justify-center items-center "
                              >
                                <HeartIcon className="h-6 w-6 text-over-yonder-default" />
                              </button>
                              <span>{upvote} Likes</span>
                            </div>
                            <Link
                              params={{
                                t: event_post.title,
                                ll: `${event_post.latitude},${event_post.longitude}`,
                              }}
                              target="_blank"
                              to={`http://maps.apple.com/?q=${encodeURIComponent(
                                event_post.title
                              ).trim()}&ll=${event_post.latitude},${
                                event_post.longitude
                              }`}
                            >
                              <button
                                className={`p-2 w-fit mx-1 h-fit border rounded-lg flex flex-wrap justify-center items-center
                              `}
                              >
                                <MapPinIcon
                                  className={`h-6 w-6  text-over-yonder-default
                               `}
                                />
                              </button>
                            </Link>
                          </div>
                        </div>

                        <div
                          className={`w-initial h-initial rounded-lg overflow-hidden  lg:opacity-100  lg:w-[300px] lg:h-[300px] bg-cover bg-center`}
                          style={{
                            backgroundImage: `url(${event_post.image})`,
                          }}
                        >
                          {/* <div className="w-100 h-100 lg:hidden"></div> */}
                          <img
                            src={`${event_post.image}`}
                            alt="Post Imagess"
                            className=" h-48 w-96 object-cover lg:hidden "
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="p-4"></div> */}
                    <div className="flex md:flex-row flex-col w-full items-center px-4 py-2 bg-gray-100">
                      <Maps
                        event={event_post.title}
                        lat={event_post.latitude}
                        lng={event_post.longitude}
                      />
                      <Comments event_id={event_post.id} />
                    </div>
                  </div>

                  // < to={`/event/${event_post.id}`}>
                  // <div
                  //   className=" w-full md:w-1/2  lg:w-3/4 flex flex-col lg:flex-row h-auto lg:h-[500px] mt-14 mb-10 shadow-md"
                  //   key={event_post.id}
                  // >
                  //   <div
                  //     className={`w-initial h-initial  lg:opacity-100  lg:w-[400px] lg:h-[500px] bg-cover bg-center`}
                  //     style={{ backgroundImage: `url(${event_post.image})` }}
                  //   >
                  //     <img
                  //       src={`${event_post.image}`}
                  //       alt="Post Imagess"
                  //       className="w-initial lg:hidden h-100"
                  //     />
                  //   </div>
                  //   <div className="w-full lg:w-[calc(100%-400px)] justify-between flex flex-col h-auto">
                  //     <Link to={`/user/${event_post.user_profiles.id}`}>
                  //       <div className="w-[100%] flex flex-row-reverse flex-wrap items-center py-2 lg:py-0 px-2 ">
                  //         <img
                  //           className="h-[40px] w-[40px] rounded-[50%] avatar border cursor-pointer border-over-yonder"
                  //           src={event_post.user_profiles.avatar_url}
                  //           alt="avatar"
                  //           title={
                  //             event_post.user_profiles.full_name ||
                  //             `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`
                  //           }
                  //         />
                  //         <p className="mx-2">
                  //           {event_post.user_profiles.full_name ||
                  //             `${event_post.user_profiles.first_name} ${event_post.user_profiles.last_name}`}
                  //         </p>
                  //       </div>
                  //     </Link>
                  //     <div className="px-2 flex flex-col lg:overflow-y-scroll">
                  //       <h1 className="mt-2 mb-4 text-2xl font-bold text-right">
                  //         {event_post.title}
                  //       </h1>
                  //       <p className=" text-sm text-right ">
                  //         {event_post?.description ? event_post?.description.slice(0, 50):""}...
                  //       <br/>
                  //         <Link to={`/event/${event_post.id}`}>Read more</Link>
                  //       </p>
                  //       {/* <p className=" text-2xl text-right">
                  //       {event_post?.schools?.college_name}
                  //     </p> */}
                  //       {/* <p className=" text-sm text-right">
                  //       Starts - {readableDate(event_post.start_date)}
                  //     </p>
                  //     <p className=" text-sm text-right">
                  //       Ends - {readableDate(event_post.end_date)}
                  //     </p> */}
                  //       <div>
                  //         <div className="py-2 flex flex-row items-center justify-end">
                  //           {upvote}

                  //           {``}
                  //           {/* {`${user_event.filter(user => user.event_id === event_post.id).length}`} */}
                  //           {/* {user_event.find(user => user.user_id === user.id)  ? ("A") : ("B")} */}
                  //         </div>
                  //       </div>
                  //     </div>
                  //     <Comments event_id={event_post.id} />
                  //   </div>
                  // </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
}

export default Events;
