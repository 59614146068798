/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import React, { useState } from "react";
import Modal from "./features/createAccountModal";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import mobile from "./assets/imgs/mobile_view.png";
import Footer from "./components/Footer";

function App() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const showModal = () => {
    setShow(!show);
    if (show1) {
      setShow1(!show1);
    }
  };
  const showModal1 = () => {
    setShow1(!show1);
    if (show) {
      setShow(!show);
    }
  };

  return (
    <main className="App">
      <section className="main-container">
        <Header />
        <div className="hero">
          <div className="hero-content h-auto">
            <h1
              data-testid="hero-text"
              className="font-extrabold text-5xl md:text-8xl  md:p-2 text-left	text-white"
            >
              What's OverYonder?
            </h1>
            <p className="text-left md:text-center text-white">
              An app that enables college students to get more involved on
              campus!
            </p>
            {/** Margin should be mt-12 */}
            <div className="w-full h-fit flex flex-col md:flex-row justify-center items-center mt-1">
              {/* <p className="text-white text-xs">Web version coming soon!</p> */}
              <div className="w-full h-fit flex flex-col md:flex-row justify-center items-center mt-12">
                <Modal
                  title="Create Account"
                  isCreateAccount={true}
                  onClose={showModal}
                  show={show}
                />
                <Modal title="Log In" onClose={showModal1} show={show1} />
                <a
                  className="bg-white w-40 h-10 text-center px-1 py-2 mx-2 text-over-yonder-default rounded-md decor-none cursor-pointer"
                  onClick={(e) => {
                    showModal(e);
                  }}
                >
                  Create Account
                </a>
                <span>or</span>
                <a
                  className="bg-over-yonder-default w-40 mx-2 h-10 text-center px-1 py-2 text-white rounded-md decor-none cursor-pointer"
                  onClick={(e) => {
                    showModal1(e);
                  }}
                >
                  Log In
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="container-download flex py-16 flex-wrap flex-col justify-center content-center"
        id="download"
      >
        <div className="download w-3/4 h-5/6 flex flex-row flex-wrap justify-evenly items-center bg-over-yonder-default">
          <div className="px-10">
            <img src={mobile} alt="OverYonder Mobile view" />
          </div>
          <div className=" w-1/2 h-full flex flex-col flex-wrap">
            <h2 className="text-4xl font-bold text-white">Download the App</h2>
            {/* <p className="text-white py-2">
              
            </p> */}
            <div className="flex md:flex-row flex-col">
              <Link to="/" className="flex self-center">
                <img
                  className="md:h-[55px] h-[50px]"
                  loading="lazy"
                  src="./assets/img/app-store-download.png"
                  alt="OverYonder for IOS"
                  title="OverYonder for IOS Download"
                />
              </Link>
              <Link to="/">
                <img
                  loading="lazy"
                  className="h-[80px]"
                  src="./assets/img/google-play-badge.png"
                  alt="OverYonder for Android"
                  title="OverYonder for Android Download"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full h-auto flex flex-wrap flex-col py-16 bg-white justify-center content-center">
        <div
          id="about-us"
          className="about-us flex w-1/2 md:w-1/2 py-5 md:flex-row flex-col justify-center flex-grow-1 items-center"
        >
          <h3 className=" text-over-yonder-default py-2 md:py-0 md:m-[0.345em] h-fit font-bold text-4xl md:-rotate-90 w-fit">
            About Us
          </h3>
          <div className="text justify-center">
            <p className="text-black text-xl">
              <h6 className="md:block inline">OverYonder </h6>
              is a social media platform built to help college students feel
              more connected to campus life. Students can use it to post about
              upcoming events, share information on clubs and organizations, and
              interact with other students.
            </p>
          </div>
        </div>
        <div className="about-us flex w-1/2 md:w-1/2 py-5 md:flex-row flex-col justify-between align-middle items-center">
          <h3 className="h-fit py-2 md:py-0 text-over-yonder-default font-bold text-4xl md:-rotate-90 w-fit">
            Our Mission
          </h3>
          <div className="text justify-center">
            <p className="text-black text-xl">
              Key features include an interactive campus map showing event
              locations, comment capabilities to engage with peers, and a
              customized feed showing relevant campus happenings. OverYonder
              makes it easy for students to stay in-the-know about campus
              activities, meet new people, and find groups aligned with their
              interests. Unlike existing social platforms, OverYonder is focused
              entirely on enhancing the college experience by facilitating
              student involvement and engagement.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default App;
