import React from "react";

const Loading = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center align-center bg-[#5a3283]">
      {/* <img
        alt="OverYonder Logo"
        src="../../public/assets/img/OverYonderLogo.png"
      /> */}
      <h1 className="font-bold text-5xl text-center text-white">OverYonder</h1>
      <p className="font-bold text-3xl text-center text-white">Be More Involved</p>
    </div>
  );
};

export default Loading;
