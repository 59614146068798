
import Compressor from "compressorjs";

async function convertJPGCompress(file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6, // Compress to 60% quality
      mimeType: "image/jpeg",
      width: 1000,
      height: 1000,
      convertSize: Infinity, // Convert regardless of file size
      success(compressedFile) {
        // Rename the file to `converted_${Date.now()}.jpg`
        const jpgFileName = `converted_${Date.now()}.jpg`;

        // Create a new File object with the compressed file and new name
        const finalFile = new File([compressedFile], jpgFileName, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        console.log(file)
        resolve(finalFile);
      },
      error(err) {
        reject(err);
      },
    });
  });
}

export { convertJPGCompress };
